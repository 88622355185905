<template>
  <td class="text-center">
    <div class="checkbo">
      <input
        type="checkbox"
        v-model="props.data.checked"
        :value="
          props.extra && props.extra.includes(props.data.document_number)
            ? true
            : props.data.checked
        "
        @change="checkboxOrder"
        class="form-check-input"
      />
    </div>
  </td>

  <td v-for="(item, i) in head" :key="i">
    <router-link
      class="unset-pad"
      :class="{
        'text-green': props.data.active === true,
        'text-yellow': props.data.active === false,
        'text-red': props.data.category === 'Finished',
        'text-blue': props.data.upcoming === true,
      }"
      :to="{
        name: 'editContract',
        params: { num: parseInt(props.data.document_number) },
      }"
    >
      <p :class="hide ? 'text-xs' : ''" v-if="['tags'].includes(item)">
        <span v-if="data">{{ $services.helpers.concatWord(data[item]) }}</span>
      </p>
      <p :class="hide ? 'text-xs' : ''" v-else>
        {{ $services.helpers.parseContent(data[item]) }}
      </p>
    </router-link>
  </td>
  <td v-if="!hide">
    <div v-if="pauseLoading === false" style="display: flex">
      <div v-if="props.data.category !== 'Finished'" style="display: flex">
        <button
          type="button"
          :title="$t('inactivate_contract')"
          @click="inactivateConfirmModal = true"
          :disabled="pauseLoading"
          v-if="props.data.active === true"
        >
          <svg
            width="8"
            height="15"
            viewbox="0 0 8 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.777303 0.612305C0.97996 0.612305 1.17432 0.69281 1.31762 0.83611C1.46092 0.97941 1.54142 1.17377 1.54142 1.37642V13.6023C1.54142 13.805 1.46092 13.9993 1.31762 14.1426C1.17432 14.2859 0.97996 14.3664 0.777303 14.3664C0.574646 14.3664 0.380289 14.2859 0.236989 14.1426C0.0936889 13.9993 0.0131836 13.805 0.0131836 13.6023V1.37642C0.0131836 1.17377 0.0936889 0.97941 0.236989 0.83611C0.380289 0.69281 0.574646 0.612305 0.777303 0.612305ZM6.89026 0.612305C7.09291 0.612305 7.28727 0.69281 7.43057 0.83611C7.57387 0.97941 7.65438 1.17377 7.65438 1.37642V13.6023C7.65438 13.805 7.57387 13.9993 7.43057 14.1426C7.28727 14.2859 7.09291 14.3664 6.89026 14.3664C6.6876 14.3664 6.49324 14.2859 6.34994 14.1426C6.20664 13.9993 6.12614 13.805 6.12614 13.6023V1.37642C6.12614 1.17377 6.20664 0.97941 6.34994 0.83611C6.49324 0.69281 6.6876 0.612305 6.89026 0.612305Z"
              fill="#5855D6"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          :title="$t('activate_contract')"
          @click="toggleContractActivity(true)"
          :disabled="pauseLoading"
          v-if="props.data.active === false"
        >
          <img
            src="../../../assets/images/icons-clickable/play.png"
            width="15"
            height="15"
          />
        </button>
        <button
          type="button"
          :title="$t('terminate_contract')"
          @click="terminateConfirmModal = true"
        >
          <!-- @click="toggleFinishContractAction" -->
          <svg
            width="13"
            height="12"
            viewbox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75303 6.0001L11.8811 11.1281M1.625 11.1281L6.75303 6.0001L1.625 11.1281ZM11.8811 0.87207L6.75206 6.0001L11.8811 0.87207ZM6.75206 6.0001L1.625 0.87207L6.75206 6.0001Z"
              stroke="#5855D6"
              stroke-width="1.46711"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          :title="$t('schedule_contract_cancelling')"
          @click="singleRowSchedule(data)"
        >
          <svg style="width: 20px; height: 19px" viewBox="0 0 24 24">
            <path
              :fill="
                props.data.scheduled_termination.length &&
                props.data.scheduled_termination.some((item) => !item.handled)
                  ? '#13b010'
                  : '#5855d6'
              "
              d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z"
            />
          </svg>
        </button>
        <button
          type="button"
          :title="$t('update_contract_prices')"
          @click="singleRowPrice(data)"
        >
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              :fill="
                props.data.scheduled_price_change.length &&
                props.data.scheduled_price_change.some((item) => !item.handled)
                  ? '#13b010'
                  : '#5855d6'
              "
              d="M21.41 11.58L12.41 2.58C12.04 2.21 11.53 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.53 2.21 12.04 2.59 12.41L3 12.81C3.9 12.27 4.94 12 6 12C9.31 12 12 14.69 12 18C12 19.06 11.72 20.09 11.18 21L11.58 21.4C11.95 21.78 12.47 22 13 22S14.04 21.79 14.41 21.41L21.41 14.41C21.79 14.04 22 13.53 22 13S21.79 11.96 21.41 11.58M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7M5 17V21H7V17H9L6 14L3 17H5Z"
            />
          </svg>
        </button>
      </div>
      <button type="button" :title="$t('tag_contract')" @click="openTag(data)">
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            :fill="
              props.data.tags && props.data.tags.length ? '#13b010' : '#5855d6'
            "
            d="M21.41 11.58L12.41 2.58A2 2 0 0 0 11 2H4A2 2 0 0 0 2 4V11A2 2 0 0 0 2.59 12.42L11.59 21.42A2 2 0 0 0 13 22A2 2 0 0 0 14.41 21.41L21.41 14.41A2 2 0 0 0 22 13A2 2 0 0 0 21.41 11.58M13 20L4 11V4H11L20 13M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5Z"
          />
        </svg>
      </button>
    </div>
    <div v-else>
      <icon_loading />
    </div>
  </td>
  <ModalBoxVue
    v-if="terminateConfirmModal"
    @handleClose="terminateConfirmModal = false"
    :close="!true"
  >
    <div class="container p-3">
      <img src="@/assets/images/modal/notify-warning.png" width="25%" />

      <p>
        {{ $t("finishContractConfirmation") }}
        {{ this.data.document_number }}?
      </p>
      <div class="confirmModalButtons">
        <button
          class="btn btn-outline-dark"
          @click="terminateConfirmModal = false"
        >
          {{ $t("cancel") }}
        </button>
        <button
          class="btn btn-info--confirm"
          @click="toggleFinishContractAction"
        >
          {{ $t("yes") }}
        </button>
      </div>
    </div>
  </ModalBoxVue>
  <ModalBoxVue
    v-if="inactivateConfirmModal"
    @handleClose="inactivateConfirmModal = false"
    :close="!true"
  >
    <div class="container p-3">
      <img src="@/assets/images/modal/notify-warning.png" width="25%" />
      <p>
        {{ $t("inactivateContractConfirmation") }}
        {{ this.data.document_number }}?
      </p>
      <div class="confirmModalButtons">
        <button
          class="btn btn-outline-dark"
          @click="inactivateConfirmModal = false"
        >
          {{ $t("cancel") }}
        </button>
        <button
          class="btn btn-info--confirm"
          @click="toggleContractActivity(false)"
        >
          {{ $t("yes") }}
        </button>
      </div>
    </div>
  </ModalBoxVue>
</template>

<script>
import { mapGetters, useStore } from "vuex";
import icon_loading from "../../../components/icon_loading";
import ModalBoxVue from "@/components/ModalBox.vue";

export default {
  props: {
    data: Object,
    head: Array,
    hide: Boolean,
    extra: Array,
  },
  components: { icon_loading, ModalBoxVue },
  emits: [
    "openTag",
    "toggleActivity",
    "toggleAction",
    "singleRowSchedule",
    "singleRowPrice",
  ],
  setup(props, { emit }) {
    const store = useStore();

    const checkboxOrder = (e) =>
      store.commit("clients/TOGGLE_CONTRACT_CHECKBOX", {
        id: props.data.id,
        value: props.data.checked,
      });
    const openContractTag = () => {
      // store.commit('clients/TOGGLE_CONTRACT_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
      emit("openTag", props.data);
    };

    const toggleAction = () => {
      // store.commit('clients/TOGGLE_CONTRACT_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
      emit("toggleAction", props.data);
    };

    const toggleActivity = () => {
      // store.commit('clients/TOGGLE_CONTRACT_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
      // emit('toggleActivity', props.data)
    };

    const singleRowSchedule = (data) => {
      emit("singleRowSchedule", data);
    };

    const singleRowPrice = (data) => {
      emit("singleRowPrice", data);
    };

    return {
      props,
      checkboxOrder,
      toggleAction,
      openContractTag,
      toggleActivity,
      singleRowSchedule,
      singleRowPrice,
    };
  },
  data: function () {
    return {
      pauseLoading: false,
      terminateConfirmModal: false,
      inactivateConfirmModal: false,
    };
  },
  methods: {
    concatWord(item) {
      if (typeof item === "object") {
        if (item !== "" && item !== null) {
          try {
            return item.join(",");
          } catch (e) {
            return "";
          }
        }
      }
    },
    toggleContractActivity(status) {
      this.pauseLoading = true;
      let data = this.data;
      data.active = status;
      let invoice_row = data.invoice_rows;
      let new_invoice_rows = [];
      for (const [key, value] of Object.entries(data)) {
        if (value === "" || value === null) {
          delete data[key];
        }
      }
      invoice_row.map((item) => {
        for (const [key, value] of Object.entries(item)) {
          if (value === "" || value === null) {
            delete item[key];
          }
        }
        new_invoice_rows.push(item);
      });
      this.$store
        .dispatch("clients/updateContract", {
          document_number: data.document_number,
          active: status,
        })
        .then((res) => {
          this.pauseLoading = false;
          this.inactivateConfirmModal = false;
          if (status === true) {
            // this.$services.helpers.notification('Successfully activate contract', 'success', this)
            this.$toast.success(this.$t("success_activate_contract"));
          } else {
            // this.$services.helpers.notification('Successfully in-activate contract', 'success', this)
            this.$toast.success(this.$t("success_inactivate_contract"));
          }
        })
        .catch((err) => {
          this.pauseLoading = false;
          this.inactivateConfirmModal = false;

          data.active = !status;
          if (err.response.status === 403) {
            this.$services.helpers.notification(
              err.response.data,
              "error",
              this
            );
          } else {
            try {
              this.$services.helpers.notification(
                err.response.data.message.data,
                "error",
                this
              );
            } catch (e) {
              // this.$services.helpers.notification('Action could not be perform', 'error', this)
              this.$toast.error(this.$t("action_failed"));
            }
          }
        });
    },
    toggleFinishContractAction() {
      this.pauseLoading = true;
      const payload = {
        action: "finish",
        id: this.data.document_number,
      };
      this.$store
        .dispatch("clients/updateContractSingleAction", payload)
        .then((res) => {
          this.pauseLoading = false;
          this.terminateConfirmModal = false;

          // this.$services.helpers.notification('Contract terminated successfully', 'success', this)
          this.$toast.success(this.$t("contract_terminated"));

          let contractIndex = this.GET_CONTRACTS.data.findIndex(
            (item) => item.document_number === payload.id
          );
          if (contractIndex !== -1) {
            this.GET_CONTRACTS.data[contractIndex].category = "Finished";
          }
        })
        .catch((err) => {
          this.pauseLoading = false;
          this.terminateConfirmModal = false;

          if (err.response.status === 403) {
            this.$services.helpers.notification(
              err.response.data,
              "error",
              this
            );
          } else {
            try {
              this.$services.helpers.notification(
                err.response.data.message.data,
                "error",
                this
              );
            } catch (e) {
              // this.$services.helpers.notification('Action could not be perform', 'error', this)
              this.$toast.error(this.$t("action_failed"));
            }
          }
        });
    },
    openTag(data) {
      window.Bus.emit("open-single-tag-contract", data);
    },
  },
  computed: {
    ...mapGetters({
      GET_CONTRACTS: "clients/GET_CONTRACTS",
    }),
  },
};
</script>

<style lang="scss" scoped>
.content .box table {
  width: 100%;
  min-width: 1041px;
}

.text-green {
  color: #419e6a !important;
}

.text-yellow {
  color: #efb008;
}

.text-blue {
  color: #4d82f3;
}

.text-red {
  color: #fb4b4b;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 5px;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.text-green {
  color: #419e6a;
}

.text-yellow {
  color: #efb008;
}

.content .box table thead tr {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table thead th .checkbox {
  margin: 0 5px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr.terminated td:last-of-type button:nth-of-type(1),
.content .box table tbody tr.terminated td:last-of-type button:nth-of-type(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.inactive td:last-of-type button:nth-of-type(1) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
  text-align: right;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td span {
  display: block;
}

.content .box table tbody td span.inactive {
  background: #ffde81;
  color: #976400;
}

.content .box table tbody td span.terminated {
  background: #ffc4c4;
  color: #fb0000;
}

.content .box table tbody td span.active {
  background: #a5e1bf;
  color: #00632b;
}

.content .box table tbody td span.upcoming {
  background: #7ea5f8;
  color: #fbfbfb;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.content .box table tbody td button {
  margin-right: 10px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 575.98px) {
  .content .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content .actions * {
    margin: 5px !important;
  }
}

.confirmModalButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;

  & .btn-info--confirm {
    color: #fff;
    background-color: #00d4c1;
    border-color: #00d4c1;
  }
}
</style>
